import FiltersMixin from '../../../mixins/filtersMixins';
import customerServices from '../../../services/customer';
import eventModule from '../../../services/event';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');
import { userFilterSchema, validate } from '../../../services/validation';
import async from 'async';

export default {
  name: 'userPropertyList',
  components: {
    BeatLoader
  },
  props: {
    filterTitle: String,
    readOnly: Boolean,
    rawPropertyFilter: Object,
    formattedPropertyFilter: Object
  },
  data() {
    return {
      matchAll: true,
      userPropertyList: [],
      currentUserProperty: null,
      fetchingUserProperty: false,

      userPropertyFilter: {
        filters: [],
        performedFilter: [],
        notPerformedFilter: [],
        matchAll: true
      },

      eventList: [],
      eventMetadata: {},
      campaignList: [],
      campaignResponseTypeList: [
        {
          name: 'Opened',
          value: 'opened'
        },
        {
          name: 'Clicked',
          value: 'clicked'
        },
        {
          name: 'Delivered',
          value: 'delivered'
        },
        {
          name: 'Not Opened',
          value: 'not_opened'
        },
        {
          name: 'Not Clicked',
          value: 'not_clicked'
        },
        {
          name: 'Not Delivered',
          value: 'not_delivered'
        }
      ],

      // filter suugersions variables
      currentEvent: {
        name: null,
        property: null
      }
    };
  },
  mixins: [FiltersMixin],
  methods: {
    /*********************Start: User Property *********************/

    fetchUserPropertyList(cb) {
      // Fetch customer properties
      this.fetchingUserProperty = true;
      customerServices
        .fetchUserPropertyList(null, this)
        .then((result) => {
          if (result.data.success) {
            this.userPropertyList = result.data.data;
          }
          this.fetchingUserProperty = false;
          if (cb) cb();
        })
        .catch((error) => {
          this.reportError(error);
          this.fetchingUserProperty = false;
          if (cb) cb(error);
        });
    },

    // On property dropdown change
    onFilterUserPropertyChange(subFilter, propertyList) {
      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.columnName == subFilter.propertyName;
      });

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      if (subFilter.propertyInfo.type == 'character varying') {
        propertyType = 'string';
      }
      console.log('data type', propertyType);
      subFilter.comparisonTypeList = this.getComparisonsForDatatype(propertyType);
      if (!subFilter.comparisonType) {
        subFilter.comparisonType = subFilter.comparisonTypeList[0].key;
      }
    },

    getDisplayText(comparisonType, comparisonTypeList) {
      let data = _.find(comparisonTypeList, (c) => {
        return c.key === comparisonType;
      });
      return data.label;
    },

    addUserPropertyFilter() {
      this.userPropertyFilter.filters.push({
        propertyName: null,
        propertyInfo: null,
        comparisonTypeList: null,
        comparisonType: null,
        value: null
      });
    },

    // delete property filter
    deleteUserPropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    /*********************End: User Property *********************/

    /*********************Start: User performed or not performed event filter *********************/
    deleteEventFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    // ON property dropdown change
    onFilterPropertyChange(subFilter, propertyList, comparisionType) {
      // Read proeprty object from property name
      subFilter.propertyInfo = _.find(propertyList, (o) => {
        return o.name == subFilter.propertyName;
      });

      // Read property's datatype
      let propertyType = subFilter.propertyInfo.type;
      console.log('data type', propertyType);
      subFilter.comparisonTypeList = this.getComparisonsForDatatype(propertyType);
      subFilter.comparisonType = comparisionType ? comparisionType : subFilter.comparisonTypeList[2].key;
    },

    // Fetch event list from server
    fetchEventList(cb) {
      this.fetchingData = true;
      eventModule
        .getEventList(this)
        .then((result) => {
          let resultEventList = {};
          Object.keys(result.data.data.eventList).forEach((eventName) => {
            if (this.allSystemEvents.indexOf(eventName) >= 0) {
              if (this.eventListPageDefaultEvents.indexOf(eventName) == -1) {
                return;
              }
            }
            resultEventList[eventName] = result.data.data.eventList[eventName];
          });

          this.eventList = Object.keys(resultEventList);

          this.eventMetadata = resultEventList;
          this.fetchingData = false;
          if (cb) cb();
        })
        .catch((err) => {
          this.reportError(err);
          this.fetchingData = false;
          if (cb) cb(err);
        });
    },

    // ON +where button click
    addEventPropertyFilter(eventFilter) {
      eventFilter.filters.push({
        propertyName: null,
        propertyInfo: null,
        comparisonTypeList: null,
        comparisonType: null,
        value: null
      });
    },

    // On +inLast Click
    addEventFilterByTimestamp(eventFilter) {
      eventFilter.inLast = 30;
    },

    // On +min count click
    addEventMinimunCountFilter(eventFilter) {
      eventFilter.minCount = 1;
    },

    onEventSelect(subFilter) {
      subFilter.properties = this.eventMetadata[subFilter.eventName];
    },

    confirmEventDuration(eventFilter) {
      let tabName = eventFilter.duration.type;
      let time = eventFilter.duration;

      // let formatDate = (date) => {
      //   return moment(date).format('Do MMM,YYYY HH:mm');
      // };

      if (tabName == 'in_last') {
        if (!time.number.valueUnit) {
          return;
        } else if (eventFilter.duration.number.comparisonType == 'between' && (!time.number.valueRange[0] || !time.number.valueUnit)) {
          return;
        } else if (!time.number.value) {
          return;
        }
        time.message = `In last ${time.number.value} ${time.number.valueUnit}(s)`;
      } else if (tabName == 'exactly') {
        if (!time.number.value) {
          return;
        }
        time.number.valueUnit = 'days';
        time.number.comparisonType = '<';
        time.message = `${time.number.value} ${time.number.valueUnit}(s) ago`;
      } else if (tabName == 'before') {
        if (!time.date.value) {
          return;
        }
        time.date.comparisonType = '<';
      } else if (tabName == 'between') {
        if (time.date_range.value.length == 0) {
          return;
        }
      } else if (tabName == 'after') {
        if (!time.date.value) {
          return;
        }
        time.date.comparisonType = '>';
      }

      eventFilter.showSelector = false;
    },

    addUserPerformedEventFilter() {
      this.userPropertyFilter.performedFilter.push({
        eventName: null,
        filters: [],
        matchAnyOfSubFilters: false,
        duration: {
          type: 'in_last',
          number: {
            value: 30,
            valueRange: [15, 30],
            valueUnit: 'days',
            comparisonType: '>='
          },
          date: {
            value: null,
            comparisonType: null
          },
          date_range: {
            value: [],
            comparisonType: 'in_between'
          },
          hours: {
            enabled: false,
            start: 6,
            end: 12
          }
        },
        count: 1,
        countRange: [1, 3],
        countComparisonType: '>=',
        properties: null,
        campaignRespondedTo: '',
        campaignResponseType: ''
      });
    },

    addUserNotPerformedEventFilter() {
      this.userPropertyFilter.notPerformedFilter.push({
        eventName: null,
        filters: [],
        matchAnyOfSubFilters: false,
        duration: {
          type: 'in_last',
          message: 'In last 30 days',
          number: {
            value: 30,
            valueRange: [15, 30],
            valueUnit: 'days',
            comparisonType: '>='
          },
          date: {
            value: null,
            comparisonType: null
          },
          date_range: {
            value: [],
            comparisonType: 'in_between'
          },
          hours: {
            enabled: false,
            start: 6,
            end: 12
          }
        },
        count: 0,
        countComparisonType: '=',
        properties: null,
        campaignRespondedTo: '',
        campaignResponseType: ''
      });
    },

    /********************* End: User performed or not performed event filter *********************/

    // validateFilters() {
    //   let filter = this.userPropertyFilter;
    //   let response = {
    //     valid: true,
    //     error: null
    //   };

    //   // Validate Customer Filters
    //   for (let i = 0; i < filter.filters.length; i++) {
    //     let temp = filter.filters[i];
    //     if (!temp.propertyName || temp.comparisonType) {
    //       response.valid = false;
    //       response.error = 'Please provide valid input for customer attribute filter.';
    //       return response;
    //     } else if (!temp.value && (temp.comparisonType != 'is not null' || temp.comparisonType != 'is null')) {
    //       response.errors.push('Please provide customer attribute filter value to match with.');
    //       response.valid = false;
    //       response.error = 'Please provide customer attribute filter value to match with.';
    //       return response;
    //     }
    //   }

    //   // Performed Event
    //   for (let i = 0; i < filter.performedFilter.length; i++) {
    //     let temp = filter.performedFilter[i];
    //     if (!temp.propertyName || temp.comparisonType) {
    //       response.errors.push('Please provide valid input for customer attribute filter.');
    //     } else if (!temp.value && (temp.comparisonType != 'is not null' || temp.comparisonType != 'is null')) {
    //       response.errors.push('Please provide customer attribute filter value to match with.');
    //     }
    //   }
    // },

    getFilters() {
      try {
        let propertyFilter = JSON.parse(JSON.stringify(this.userPropertyFilter));
        let countryList = this.getCountryMapArray();
        let appliedFilter = {
          filters: [],
          performedFilter: [],
          notPerformedFilter: []
        };
        for (let i = 0; i < propertyFilter.filters.length; i++) {
          let temp = propertyFilter.filters[i];
          if (temp.propertyName && temp.comparisonType && (temp.value || (!temp.value && (temp.comparisonType == 'is not null' || temp.comparisonType == 'is null')))) {
            // Handle country name with country code
            if (temp.propertyName == 'intital_country' || temp.propertyName == 'last_country') {
              let country = _.find(countryList, (c) => {
                return c.Name == temp.value;
              });
              temp.value = country.Code;
            }

            appliedFilter.filters.push({
              propertyName: temp.propertyName,
              comparisonType: temp.comparisonType,
              propertyType: temp.propertyInfo.type,
              value: temp.value
            });
          }
        }
        for (let i = 0; i < propertyFilter.performedFilter.length; i++) {
          let temp = propertyFilter.performedFilter[i];
          let obj = {
            eventName: temp.eventName,
            filters: [],
            matchAnyOfSubFilters: temp.matchAnyOfSubFilters,
            duration: {
              type: temp.duration.type,
              number: {
                value: temp.duration.number.value,
                valueRange: temp.duration.number.valueRange,
                valueUnit: temp.duration.number.valueUnit,
                comparisonType: temp.duration.number.comparisonType
              },
              date: {
                value: temp.duration.date.value,
                comparisonType: temp.duration.date.comparisonType
              },
              date_range: {
                value: temp.duration.date_range.value,
                comparisonType: temp.duration.date_range.comparisonType
              },
              hours: temp.duration.hours
                ? temp.duration.hours
                : {
                    enabled: false,
                    start: 6,
                    end: 12
                  }
            },
            count: temp.count,
            countRange: temp.countRange,
            countComparisonType: temp.countComparisonType
          };
          obj.campaignRespondedTo = temp.campaignRespondedTo ? temp.campaignRespondedTo : '';
          obj.campaignResponseType = obj.campaignRespondedTo && temp.campaignResponseType ? temp.campaignResponseType : '';

          if (obj.eventName && obj.count && obj.countComparisonType) {
            for (let j = 0; j < temp.filters.length; j++) {
              if (temp.filters[j].propertyName && temp.filters[j].comparisonType && (temp.filters[j].value || (!temp.filters[j].value && (temp.filters[j].comparisonType == 'is not null' || temp.filters[j].comparisonType == 'is null')))) {
                obj.filters.push({
                  propertyName: temp.filters[j].propertyName,
                  comparisonType: temp.filters[j].comparisonType,
                  value: temp.filters[j].value
                });
              }
            }
            appliedFilter.performedFilter.push(obj);
          }
        }

        for (let i = 0; i < propertyFilter.notPerformedFilter.length; i++) {
          let temp = propertyFilter.notPerformedFilter[i];

          let obj = {
            eventName: temp.eventName,
            filters: [],
            matchAnyOfSubFilters: temp.matchAnyOfSubFilters,
            duration: {
              type: temp.duration.type,
              number: {
                value: temp.duration.number.value,
                valueRange: temp.duration.number.valueRange,
                valueUnit: temp.duration.number.valueUnit,
                comparisonType: temp.duration.number.comparisonType
              },
              date: {
                value: temp.duration.date.value,
                comparisonType: temp.duration.date.comparisonType
              },
              date_range: {
                value: temp.duration.date_range.value,
                comparisonType: temp.duration.date_range.comparisonType
              },
              hours: temp.duration.hours
                ? temp.duration.hours
                : {
                    enabled: false,
                    start: 6,
                    end: 12
                  }
            },
            count: temp.count,
            countComparisonType: temp.countComparisonType ? temp.countComparisonType : '='
          };
          obj.campaignRespondedTo = temp.campaignRespondedTo ? temp.campaignRespondedTo : '';
          obj.campaignResponseType = obj.campaignRespondedTo && temp.campaignResponseType ? temp.campaignResponseType : '';

          for (let j = 0; j < temp.filters.length; j++) {
            if (temp.filters[j].propertyName && temp.filters[j].comparisonType && (temp.filters[j].value || (!temp.filters[j].value && (temp.filters[j].comparisonType == 'is not null' || temp.filters[j].comparisonType == 'is null')))) {
              obj.filters.push({
                propertyName: temp.filters[j].propertyName,
                comparisonType: temp.filters[j].comparisonType,
                value: temp.filters[j].value
              });
            }
          }

          appliedFilter.notPerformedFilter.push(obj);
        }

        // Add match all/match any
        appliedFilter.matchAll = this.userPropertyFilter.matchAll;

        let result = validate(appliedFilter, userFilterSchema);
        if (result.error) {
          this.reportError(
            JSON.stringify({
              type: result.error.name,
              details: result.error.details
            })
          );
          return null;
        }

        return appliedFilter;
      } catch (e) {
        this.reportError(e);
        throw e;
      }
    },

    convertFormattedToRawFilter(formattedFilter) {
      this.userPropertyFilter.filters = [];
      this.userPropertyFilter.performedFilter = [];
      this.userPropertyFilter.notPerformedFilter = [];
      let countryList = this.getCountryMapArray();

      for (let i = 0; i < formattedFilter.filters.length; i++) {
        // Handle country name with country code
        if (formattedFilter.filters[i].propertyName == 'intital_country' || formattedFilter.filters[i].propertyName == 'last_country') {
          let country = _.find(countryList, (c) => {
            return c.Code == formattedFilter.filters[i].value;
          });
          formattedFilter.filters[i].value = country.Name;
        }

        let subFilter = {
          propertyName: formattedFilter.filters[i].propertyName,
          columnName: formattedFilter.filters[i].propertyName,
          value: formattedFilter.filters[i].value,
          comparisonType: formattedFilter.filters[i].comparisonType
        };
        this.onFilterUserPropertyChange(subFilter, this.userPropertyList);
        delete subFilter.columnName;
        this.userPropertyFilter.filters.push(subFilter);
      }

      for (let i = 0; i < formattedFilter.performedFilter.length; i++) {
        let eventFilter = {
          eventName: formattedFilter.performedFilter[i].eventName,
          duration: formattedFilter.performedFilter[i].duration,
          count: formattedFilter.performedFilter[i].count,
          countRange: formattedFilter.performedFilter[i].countRange,
          countComparisonType: formattedFilter.performedFilter[i].countComparisonType,
          matchAnyOfSubFilters: formattedFilter.performedFilter[i].matchAnyOfSubFilters ? true : false,
          filters: [],
          campaignRespondedTo: formattedFilter.performedFilter[i].campaignRespondedTo,
          campaignResponseType: formattedFilter.performedFilter[i].campaignResponseType
        };
        this.onEventSelect(eventFilter);
        for (let j = 0; j < formattedFilter.performedFilter[i].filters.length; j++) {
          let subFilter = {
            propertyName: formattedFilter.performedFilter[i].filters[j].propertyName,
            name: formattedFilter.performedFilter[i].filters[j].propertyName,
            value: formattedFilter.performedFilter[i].filters[j].value
          };
          this.onFilterPropertyChange(subFilter, eventFilter.properties, formattedFilter.performedFilter[i].filters[j].comparisonType);
          delete subFilter.name;
          eventFilter.filters.push(subFilter);
        }
        this.userPropertyFilter.performedFilter.push(eventFilter);
      }

      for (let i = 0; i < formattedFilter.notPerformedFilter.length; i++) {
        let eventFilter = {
          eventName: formattedFilter.notPerformedFilter[i].eventName,
          duration: formattedFilter.notPerformedFilter[i].duration,
          count: formattedFilter.notPerformedFilter[i].count,
          filters: [],
          matchAnyOfSubFilters: formattedFilter.notPerformedFilter[i].matchAnyOfSubFilters ? true : false,
          campaignRespondedTo: formattedFilter.notPerformedFilter[i].campaignRespondedTo,
          campaignResponseType: formattedFilter.notPerformedFilter[i].campaignResponseType
        };
        this.onEventSelect(eventFilter);
        for (let j = 0; j < formattedFilter.notPerformedFilter[i].filters.length; j++) {
          let subFilter = {
            propertyName: formattedFilter.notPerformedFilter[i].filters[j].propertyName,
            name: formattedFilter.notPerformedFilter[i].filters[j].propertyName,
            value: formattedFilter.notPerformedFilter[i].filters[j].value
          };
          this.onFilterPropertyChange(subFilter, eventFilter.properties, formattedFilter.notPerformedFilter[i].filters[j].comparisonType);
          delete subFilter.name;
          eventFilter.filters.push(subFilter);
        }
        this.userPropertyFilter.notPerformedFilter.push(eventFilter);
      }

      // set match all prop value
      this.userPropertyFilter.matchAll = formattedFilter.matchAll != undefined ? formattedFilter.matchAll : this.userPropertyFilter.matchAll;
    },

    clearFilter() {
      this.userPropertyFilter.filters = [];
      this.userPropertyFilter.performedFilter = [];
      this.userPropertyFilter.notPerformedFilter = [];
    },

    setCurrentUserProperty(filter) {
      this.currentUserProperty = filter.propertyInfo.columnName;
    },

    fetchUserPropertySuggestion(queryString, cb) {
      let params = {
        userPropertyColumnName: this.currentUserProperty,
        queryString: queryString
      };
      customerServices
        .fetchUserPropertyValueSuggetionsList(params, this)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;

            // Handle country name with country code
            if (this.currentUserProperty == 'initial_country' || this.currentUserProperty == 'last_country') {
              let countryList = this.getCountryMapArray();
              for (let i = 0; i < links.length; i++) {
                let country = _.find(countryList, (c) => {
                  return c.Code == links[i].value;
                });
                links[i].value = country.Name;
              }
            }

            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    setCurrentEventData(eventName, filter) {
      this.currentEvent.name = eventName;
      this.currentEvent.property = filter.propertyInfo.name;
    },

    fetchEventPropertySuggestion(queryString, cb) {
      let params = {
        eventName: this.currentEvent.name,
        property: this.currentEvent.property,
        queryString: queryString
      };
      eventModule
        .fetchCustomEventValueSuggetionsList(params)
        .then((result) => {
          if (result.data.success) {
            var links = result.data.data;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
          }
        })
        .catch((e) => {
          cb();
          this.reportError(e);
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    onDurationTypeChange(eventFilter) {
      if (eventFilter.duration.type == 'in-hours-of-day') {
        eventFilter.duration.number.valueRange = [18, 22];
        eventFilter.duration.valueUnit = 'hours';
      } else if (eventFilter.duration.type == 'in_last') {
        eventFilter.duration.number.valueRange = [15, 30];
        eventFilter.duration.valueUnit = 'days';
      }
    },

    /********************* Start: Filters Dropdown Actions **************************/

    handleUserFilterAction(filterList, index, action) {
      if (action === 'clone') {
        const userFilter = filterList[index];
        let cloned = JSON.parse(JSON.stringify(userFilter));
        filterList.splice(index + 1, 0, cloned);
      } else if (action === 'delete') {
        this.deleteUserPropertyFilter(filterList, index);
      }
    },

    handlePerformedFilter(filterList, index, action) {
      if (action === 'clone') {
        const eventFilter = filterList[index];
        let cloned = JSON.parse(JSON.stringify(eventFilter));
        filterList.splice(index + 1, 0, cloned);
      } else if (action === 'delete') {
        this.deleteEventFilter(filterList, index);
      }
    },

    handlePerformedSubFilter(eventFilterList, index, action) {
      if (action === 'clone') {
        const eventFilter = eventFilterList[index];
        let cloned = JSON.parse(JSON.stringify(eventFilter));
        eventFilterList.splice(index + 1, 0, cloned);
      } else if (action === 'delete') {
        this.deleteEventFilter(eventFilterList, index);
      }
    },

    /********************* End: Filters Dropdown Actions **************************/

    /********************* Start: Util functions **************************/

    get365NumberList() {
      let arr = [];
      for (let i = 1; i < 365; i++) {
        arr.push(i);
      }
      return arr;
    },

    getDisplayHour(hourNumber) {
      let amPm = hourNumber < 12 ? 'AM' : 'PM';
      let appendZero = hourNumber < 12 ? '0' : '';

      return appendZero + hourNumber + ' ' + amPm;
    },

    setExistingFilters() {
      if (this.rawPropertyFilter && (Object.keys(this.rawPropertyFilter).length === 4 || Object.keys(this.rawPropertyFilter).length === 3)) {
        this.userPropertyFilter = this.rawPropertyFilter;
        this.fetchingUserProperty = false;
      } else if (this.formattedPropertyFilter && (Object.keys(this.formattedPropertyFilter).length === 4 || Object.keys(this.formattedPropertyFilter).length === 3)) {
        let item = this;
        // Fetch user and event prop list before formatting to raw filter
        async.parallel(
          {
            userProp: function (cb) {
              item.fetchUserPropertyList(function (err) {
                if (err) return cb(err);
                cb();
              });
            },
            eventProp: function (cb) {
              item.fetchEventList(function (err) {
                if (err) return cb(err);
                cb();
              });
            }
          },
          function (err) {
            if (err) {
              return item.errorToast(`Something went wrong.`);
            }
            // Convert formatted filter to raw ffilter
            item.convertFormattedToRawFilter(item.formattedPropertyFilter);
            item.fetchingUserProperty = false;
          }
        );
      }
    },

    restFilter(newFilter) {
      this.formattedPropertyFilter = newFilter;
      this.setExistingFilters();
    }
  },

  mounted() {
    this.setExistingFilters();

    if (!this.readOnly) {
      this.fetchUserPropertyList(null);
      this.fetchEventList();
    }
  }
};
